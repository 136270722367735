export default {
    countCells: 11,
    massive: [
        {
            dataField: 'org',
            cols: 4,
            rows: 1,
            text: 'Организация',
            // class: 'd-flex flex-row align-center justify-start  ',
            // headerClass: 'header-table-cell align-end border-bottom-zero',
            headerClass: 'sborChisl__table-header',
            headerStyles: `min-width: 305px; justify-content: flex-start; padding: 16px 0 22px 18px`,
            order: 0,
            variable: true,
        },
        {
            dataField: 'dep',
            cols: 4,
            rows: 1,
            text: `Министерство`,
            // class: 'justify-start',
            headerClass: 'sborChisl__table-header',
            // headerClass: ' align-start border-bottom-zero table-header-info',
            headerStyles: `min-width: 160px; justify-content: flex-start; padding: 16px 0 22px 18px`,
            order: 1,
            variable: false,
        },
        {
            dataField: 'scans',
            cols: 1,
            rows: 1,
            text: `Сканы`,
            order: 2,
            variable: true,
            // headerClass: 'd-flex align-start',
            headerClass: 'sborChisl__table-header',
            headerStyles: `min-width: 100px; justify-content: flex-start; padding: 16px 0 22px 13px`,
        },
        {
            dataField: 'conv',
            cols: 1,
            rows: 1,
            text: `Конверт`,
            order: 3,
            variable: false,
            // class: `justify-center `,
            // headerClass: 'align-start  border-bottom-zero',
            headerClass: 'sborChisl__table-header',
            headerStyles: `min-width: 125px; justify-content: flex-start; padding: 16px 0 22px 16px`,
        },
        {
            dataField: 'block',
            cols: 1,
            rows: 1,
            text: 'Блокировка',
            order: 4,
            variable: false,
            // headerClass: 'align-start border-bottom-zero',
            headerClass: 'sborChisl__table-header',
            headerStyles: `min-width: 125px; justify-content: flex-start; padding: 16px 0 22px 16px`,
        },
        {
            dataField: 'orgIdFilter',
            cols: 1,
            rows: 1,
            text: '',
            order: 5,
            variable: true,
            // class: 'd-flex flex-row align-center justify-start  ',
            // headerClass: 'header-table-cell align-end border-bottom-zero',
            headerClass: 'sborChisl__table-filter',
            headerStyles: `min-width: 57px; border-left: 0; padding: 8px 10px`,
        },
        {
            dataField: 'orgNameFilter',
            cols: 3,
            rows: 1,
            text: '',
            order: 5,
            variable: false,
            // headerClass: 'align-start border-bottom-zero',
            headerClass: 'sborChisl__table-filter',
            headerStyles: `padding: 8px 10px`,
        },
        {
            dataField: 'depIdFilter',
            cols: 1,
            rows: 1,
            text: '',
            order: 5,
            variable: false,
            // headerClass: 'align-start border-bottom-zero',
            headerClass: 'sborChisl__table-filter',
            headerStyles: `padding: 8px 10px`,
        },
        {
            dataField: 'depNameFilter',
            cols: 3,
            rows: 1,
            text: '',
            order: 5,
            variable: false,
            headerClass: 'sborChisl__table-filter',
            // headerClass: 'align-start border-bottom-zero',
            headerStyles: `padding: 8px 10px`,
        },
        {
            dataField: 'scanFilter',
            cols: 1,
            rows: 1,
            text: '',
            order: 5,
            variable: false,
            headerClass: 'sborChisl__table-filter',
            // headerClass: 'align-start border-bottom-zero',
            headerStyles: `padding: 8px 10px`,
        },
        {
            dataField: 'convFilter',
            cols: 1,
            rows: 1,
            text: '',
            order: 5,
            variable: false,
            headerClass: 'sborChisl__table-filter',
            // headerClass: 'align-start border-bottom-zero',
            headerStyles: `padding: 8px 10px`,
        },
        {
            dataField: 'blockFilter',
            cols: 1,
            rows: 1,
            text: '',
            order: 5,
            variable: false,
            headerClass: 'sborChisl__table-filter',
            // headerClass: 'align-start border-bottom-zero',
            headerStyles: `padding: 8px 10px`,
        },
    ],
    rowsMassive: [
        {
            dataField: 'region',
            cols: 1,
            rows: 1,
            text: 'Регионы',
            class: 'd-flex flex-row align-center justify-start  ',
            headerClass: 'header-table-cell align-end ',
            headerStyles: `min-width: 305px; justify-content: flex-start;`,
            order: 0,
            variable: true,
            template: r => `<div class="${r.regionId ? 'pl-5' : r.districtId ? `` : `font-weight-600`}">${r?.regionName}</div>`
        },
        {
            dataField: 'kcp21',
            cols: 1,
            rows: 1,
            order: 7,
            variable: true,
            class: ' ',
            headerClass: '',
            headerStyles: `justify-content: flex-start`,
            text: '2022',
        },
        {
            dataField: 'kcp22',
            cols: 1,
            rows: 1,
            order: 8,
            variable: true,
            class: ' ',
            headerClass: ' ',
            text: '2023 ',
        },
        {
            dataField: 'pv',
            cols: 1,
            rows: 1,
            order: 2,
            variable: true,
            headerClass: 'align-start ',
            headerStyles: `min-width: 100px`,
            text: `Потребность в подготовке кадров на долгосрочный период`,
            styles: 'justify-content: unset;',
            template: r => r?.pv
        },
        {
            dataField: 'pp',
            cols: 1,
            rows: 1,
            order: 10,
            variable: true,
            class: ``,
            headerClass: ' header-table-cell ',
            headerStyles: `padding: 8px 10px 8px 0 !important`,
            text: '2024 год',
            styles: 'justify-content: unset;',
            template: r => r?.pp
        },
        {
            dataField: 'dj',
            cols: 1,
            rows: 1,
            order: 11,
            variable: true,
            class: ``,
            headerClass: ' header-table-cell ',
            headerStyles: `padding: 8px 15px 8px 0 !important`,
            text: '2024 год',
            styles: 'justify-content: unset;',
            template: r => (r?.dj).toFixed(2) + '%'
        },
        {
            dataField: 'startValue',
            cols: 1,
            rows: 1,
            order: 12,
            variable: true,
            headerClass: ' header-table-cell ',
            class: '',
            text: '2024 год',
            headerStyles: `padding: 8px 15px 8px 0 !important`,
            styles: 'justify-content: unset;',
            template: r => r?.startValue
        },
        {
            dataField: 'stage1Value',
            cols: 1,
            rows: 1,
            order: 14,
            variable: true,
            headerClass: ' header-table-cell ',
            class: '',
            text: 'Головной ЦО',
            headerStyles: `padding: 8px 15px 8px 0 !important`,

        }, {
            dataField: 'stage2Value',
            cols: 1,
            rows: 1,
            order: 14,
            variable: true,
            headerClass: ' header-table-cell ',
            class: '',
            text: 'Субъект РФ',
            headerStyles: `padding: 8px 15px 8px 0 !important`,

        }, {
            dataField: 'stage3Value',
            cols: 1,
            rows: 1,
            order: 14,
            variable: true,
            headerClass: ' header-table-cell ',
            class: '',
            text: 'Головной ЦО',
            headerStyles: `padding: 8px 15px 8px 0 !important`,

        },
        {
            dataField: 'value',
            cols: 1,
            rows: 1,
            order: 14,
            variable: true,
            headerClass: ' header-table-cell',
            styles: 'justify-content: unset;',
            text: 'ЦО',
            headerStyles: `padding: 8px 15px 8px 0 !important; `,
        },
    ]
}


// massive: [
//     {
//         dataField: 'org',
//         cols: 4,
//         rows: 1,
//         text: 'Организация',
//         class: 'd-flex flex-row align-center justify-start  ',
//         headerClass: 'header-table-cell align-end border-bottom-zero',
//         headerStyles: `min-width: 305px; `,
//         order: 0,
//         variable: true,
//     },
//     {
//         dataField: 'dep',
//         cols: 4,
//         rows: 1,
//         text: `Министерство`,
//         class: 'justify-start',
//         headerClass: ' align-start border-bottom-zero table-header-info',
//         headerStyles: `min-width: 160px`,
//         order: 1,
//         variable: false,
//     },
//     {
//         dataField: 'scans',
//         cols: 1,
//         rows: 1,
//         text: `Сканы`,
//         order: 2,
//         variable: true,
//         headerClass: 'd-flex align-start',
//         headerStyles: `min-width: 100px`,
//     },
//     {
//         dataField: 'conv',
//         cols: 1,
//         rows: 1,
//         text: `Конверт`,
//         order: 3,
//         variable: false,
//         class: `justify-center `,
//         headerClass: 'align-start  border-bottom-zero',
//         headerStyles: `min-width: 115px`,
//     },
//     {
//         dataField: 'block',
//         cols: 1,
//         rows: 1,
//         text: 'Блокировка',
//         order: 4,
//         variable: false,
//         headerClass: 'align-start border-bottom-zero',
//         headerStyles: ``,
//     },
// ],