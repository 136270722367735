<template>
  <div class="sborChisl">
    <div class="sborChisl__btn-div">
      <button class="sborChisl__btn">Полная выгрузка</button>
      <button class="sborChisl__btn">Расчет квот по ВУЗам</button>
      <button class="sborChisl__btn">Расчет квот по ФОИВ</button>
      <button class="sborChisl__btn">Расчет квот по ВУЗам СПО</button>
    </div>
    <div class="sborChisl__header">
      <p class="sborChisl__header-text">Наименование таблицы</p>
      <img src="@/assets/icons/prev-page.svg" class="sborChisl__page-btn" alt="prev-page">
      <img src="@/assets/icons/next-page.svg" class="sborChisl__page-btn" alt="next-page">
    </div>
    <GridTable
      :headers="headers.massive"
      :headers-for-rows="headersForRows"
      :num-cols="headers.countCells"
      :loading-data="loadingData"
      :data-rows="showingData"
      loader-color="#78909C"
    >
      <template #header-orgIdFilter>
        <span>
          5555
        </span>
      </template>
    </GridTable>
  </div>
</template>

<script>
import SborChislHeaders from "@/layouts/sborChisl/SborChislHeaders";
import {GridTable} from '@frontenddevelopers/ined-components/src/lib';

export default {
  name: "SborChisl",
  components: {GridTable},
  data() {
    return {
      headers: SborChislHeaders,
      loadingData: false,
      showingData: [],
    }
  },
  computed: {
    headersForRows() {
      return this.headers.rowsMassive
    },
  },
}
</script>

<style scoped>

</style>