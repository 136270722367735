import { render, staticRenderFns } from "./SborChisl.vue?vue&type=template&id=5068c426&scoped=true&"
import script from "./SborChisl.vue?vue&type=script&lang=js&"
export * from "./SborChisl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5068c426",
  null
  
)

export default component.exports